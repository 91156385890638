import './lib/polyfills'
import './components'
import './lazyload'

addEventListener('DOMContentLoaded', (event) => {
    initializeForms()
    initMenu()

    // Division dropdown.
    const dropdown = document.querySelector('#division_id')
    if (dropdown) {
        dropdown.addEventListener('change', () => dropdown.input.form.submit())
    }
})

// Mark a <form> with the class `busy` when it's being submitted.
function initializeForms() {
    const forms = document.querySelectorAll('form')
    for (let a = 0; a < forms.length; a++) {
        forms[a].addEventListener('submit', function (event) {
            if (document.activeElement) {
                document.activeElement.blur()
                console.log(document.activeElement)
            }

            this.classList.add('busy')
        })
    }
}

function initMenu() {
    const header = document.querySelector('header')

    addEventListener('scroll', (event) => {
        const y = window.scrollY || document.body.scrollTop
        const className = 'compact'

        if (y > 50 && !header.classList.contains(className)) {
            header.classList.add(className)
        }
        else if (y <= 50 && header.classList.contains(className)) {
            header.classList.remove(className)
        }
    })
}