import jsx from '../lib/jsx'
import { dispatchEvent } from '../lib/helpers'

addEventListener('DOMContentLoaded', () => {
	const selects = document.querySelectorAll('select:not(.selector_select)')
	for (let a = 0; a < selects.length; a++) {
		new Dropdown(selects[a])
	}
})

/**
 * A more beautiful 'dropdown' than what the browser provides.
 */
export default function Dropdown(obj = {}) {
	let self = <ui-dropdown type='dropdown'/>
	Object.defineProperties(self, Object.getOwnPropertyDescriptors(Dropdown.prototype))

	// Save these to replace the element later on.
	let parent, nextSibling
	let selectedIndex = 0

	// Initialize with a DOM element.
	if (obj instanceof HTMLElement) {
		if (!(obj instanceof HTMLSelectElement)) {
			throw new Error('Element is not of type `HTMLSelectElement`.')
		}
		parent = obj.parentNode
		nextSibling = obj.nextElementSibling
		parent.removeChild(obj)

		selectedIndex = obj.selectedIndex
		self.id = obj.id
		obj.id = ''
	}

	//
	// (Private) events.
	const onItemClick = (event) => {
		self.select(event.target.value)
		self.close()
	}

	// Hidden `input` to pass the value to the form.
	self.input = document.createElement('input')
	self.input.name = obj.name
	self.input.type = 'hidden'
	self.input.required = obj.required

	// Turn into valid options.
	let options = [...(obj.children || obj.options || [])]

	options = options.map(function map(option) {
		if (option instanceof HTMLOptGroupElement) {
			let group =
				<div class='group'>
					{ [...option.children].map(map) }
				</div>
			group.setAttribute('label', option.label)
			return group
		}
		else if (option instanceof HTMLOptionElement) {
			return (
				<div class='option'
					onClick={onItemClick}
					value={(option.value || option.textContent || '').trim()}>
					{ option.textContent || option.value }
				</div>
			)
		}
		else {
			return (
				<div class='option' onClick={onItemClick} value={option}>
					{ option }
				</div>
			)
		}
	})

	let selected = options[selectedIndex]
	self.input.value = selected.value

	self.appendChild(
		<>
			{ self.input }
			<div class='selected'
				onClick={self.toggle.bind(self)}>
				<span ref={el => self.selectedSpan = el}>
					{selected && selected.textContent}
				</span>
			</div>
			<div class='options'
				ref={el => self.optionsHolder = el}>
				{ options }
			</div>
		</>
	)

	// Replace the DOM element.
	if (parent) {
		if (nextSibling) {
			parent.insertBefore(self, nextSibling)
		}
		else {
			parent.appendChild(self)
		}
	}

	self.select(selected.value)

	return self
}

Dropdown.prototype = {
	select(obj) {
		const currentValue = this.value

		let selectedElement = null
		const options = this.options

		if (typeof obj == 'string') {
			selectedElement = options.find(function find(option){
				if (option.classList.contains('option')) {
					return option.value == obj
				}
				else if (option.classList.contains('group')) {
					return [...option.children].find(find)
				}
			})
		}
		else if (typeof obj == 'number') {
			selectedElement = options[obj]
		}

		selectedElement = selectedElement || options[0]

		if (selectedElement) {
			this.selectedSpan.textContent = selectedElement.textContent
			this.input.value = selectedElement.value || ''

			for (const option of options) {
				const fnName = option == selectedElement ? 'add' : 'remove'
				option.classList[fnName]('current')
			}
		}

		if (this.value != currentValue) {
			dispatchEvent(this, 'change')
		}

		return this.input.value
	},

	toggle() {
		if (!this.classList.contains('open')) {
			this.open()
		}
		else {
			this.close()
		}
	},

	open() {
		closeOpenDropdowns()
		this.optionsHolder.scrollTop = 0
		this.classList.add('open')
	},

	close() {
		this.classList.remove('open')
	},

	get disabled() {
		return this.classList.contains('disabled')
	},

	set disabled(val) {
		this.classList[!!val ? 'add' : 'remove']('disabled')
		return val
	},

	get name() {
		return this.input.name
	},

	get options() {
		return [...this.optionsHolder.children]
	},

	get value() {
		return this.input.value
	},

	set value(val) {
		return this.select(val.toString())
	}
}

function closeOpenDropdowns() {
	let openDropdowns = document.querySelectorAll('ui-dropdown.open')
	for (let a = 0; a < openDropdowns.length; a++) {
		openDropdowns[a].close()
	}
}

// Close any open dropdowns on touches outside a dropdown.
for (const event of ['mousedown', 'touchstart', 'pointerdown']) {
	addEventListener(event, ({ target }) => {
		let openDropdowns = [...document.querySelectorAll('ui-dropdown.open')]

		if (openDropdowns.length) {
			let node = target
			while ((node = node.parentNode)) {
				if (node.tagName == 'UI-DROPDOWN') {
					openDropdowns = openDropdowns.filter(dropdown => dropdown !== node)
					break
				}
			}

			openDropdowns.forEach(dropdown => dropdown.close())
		}
	})
}