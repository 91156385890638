import jsx from '../lib/jsx'
import { dispatchEvent } from '../lib/helpers'

addEventListener('DOMContentLoaded', () => {
	const elements = document.querySelectorAll('div.selection-list')
	for (let a = 0; a < elements.length; a++) {
		new SelectionList(elements[a])
	}
})

/**
 * The selection element.
 */
export default function SelectionList(obj = {}) {
	let self = <ui-selection-list/>
	Object.defineProperties(self, Object.getOwnPropertyDescriptors(SelectionList.prototype))

	let parent, nextSibling

	/**
	 * First collect the settings.
	 * Either take it from a dictionary or the HTML element.
	 */
	self.name = obj.name || obj.getAttribute('name') || 'selection_' + Math.round(Math.random() * 1e8)
	self.options = obj.options || []

	let placeholder = ''

	// Initialize with a DOM element.
	if (obj instanceof HTMLElement) {
		// placeholder.
		const placeholderEl = obj.querySelector('div.placeholder')
		placeholder = placeholderEl.textContent

		// Find options.
		const optionElements = obj.querySelectorAll('div.option')
		for (const optionElement of optionElements) {
			self.options.push({
				selected: optionElement.classList.contains('selected'),
				text: optionElement.textContent,
				value: optionElement.getAttribute('value')
			})
		}

		parent = obj.parentNode
		nextSibling = obj.nextElementSibling
		parent.removeChild(obj)
	}

	// Sort the options alphabetically.
	self.options.sort((A, B) => {
		return A.text.toLowerCase() > B.text.toLowerCase()
	})

	//
	// Events.
	// Input events.
	const onInputChange = (event) => {
		self._createOptionList()
	}

	const onInputBlur = (event) => {
		self.blur()
	}

	const onInputFocus = (event) => {
		self._createOptionList()
		self.classList.add('open')
	}

	const onKeyDown = (event) => {
		if (event.key == 'Escape') {
			event.preventDefault()
			self.blur()
		}
	}

	//
	// Elements.
	self.appendChild(
		<>
			<div class='input-holder'>
				<input ref={el => self.input = el}
					onBlur={onInputBlur}
					onFocus={onInputFocus}
					onInput={onInputChange}
					onKeyDown={onKeyDown}
					placeholder={placeholder}
					type='text'/>

				<div class='option-buttons'>
					<div class='select-all'
						onMouseDown={self.selectAll.bind(self)}>
						Select all
					</div>
				</div>

				<div class='option-list-holder'>
					<div class='option-list'
						ref={el => self.optionList = el}/>
				</div>
			</div>
			<div class='selections-holder'
				ref={el => self.selectionsHolder = el}/>
		</>
	)

	// Set selected options.
	for (const option of self.options.filter(opt => opt.selected)) {
		self.select(option.value)
	}

	// Replace the DOM element.
	if (parent) {
		if (nextSibling) {
			parent.insertBefore(self, nextSibling)
		}
		else {
			parent.appendChild(self)
		}
	}

	return self
}

SelectionList.prototype = {
	blur() {
		this.classList.remove('open')
		this.input.blur()
		this.input.value = ''
	},

	focus() {
		this.input.focus()
	},

	// Creates the list of (available) options.
	_createOptionList() {
		this.optionList.innerHTML = ''

		const list = this.filteredOptions.filter(opt => !opt.selected)

		for (const option of list) {
			this.optionList.appendChild(
				<div class='option'
					option={option}
					onMouseDown={() => this.select(option.value)}>
					{ option.text }
				</div>
			)
		}
	},

	get filteredOptions() {
		const filter = this.input.value.trim().toLowerCase()
		return this.options.filter(option => option.text.toLowerCase().includes(filter))
	},

	findOption(id) {
		return typeof id == 'string' 
			? this.options.find(opt => opt.value == id)
			: this.options[id]
	},

	// Select by value or by index.
	select(id) {
		let option = this.findOption(id)
		if (!option) {
			return
		}

		option.selected = true

		const onClick = (event) => {
			event.preventDefault()
			this.deselect(option.value)
		}

		this.selectionsHolder.appendChild(
			<div class='selected-option'
				option={option}>
				<span onClick={onClick}>
					{ option.text }
				</span>
				<input type='hidden'
					name={this.name + '_' + option.value}
					value={option.value}/>
			</div>
		)
	},

	selectAll() {
		let nonselected = this.filteredOptions.filter(opt => !opt.selected)
		for (const option of nonselected) {
			this.select(option.value)
		}
	},

	// Deselect by value or by index.
	deselect(id) {
		let option = this.findOption(id)
		if (!option) {
			return
		}

		for (const el of this.selectionsHolder.children) {
			if (el.option && el.option == option) {
				option.selected = false
				this.selectionsHolder.removeChild(el)
				break
			}
		}
	},

	deselectAll() {
		let selected = this.options.filter(opt => opt.selected)
		for (const option of selected) {
			this.deselect(option.value)
		}
	}
}